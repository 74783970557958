
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Aggregate, AggregateType } from '@/entities/public/Resource/interfaces'
  import { mapActions } from 'vuex'
  import { Query } from '@/utils/computed/Query'

@Component({
  methods: {
    ...mapActions('resources/datatables', ['fetchAggregates']),
  },
})
  export default class ChartRenderer extends Vue {
  @Prop({ type: Number, default: null }) id!: number;
  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: Boolean, default: false }) monochrome!: boolean;
  @Prop({
    type: Object,
    default: () => {
    },
  }) aggregate!: Aggregate;

  data = {
    labels: [],
    series: [],
    colors: [],
  }

  fetchAggregates!: (payload: {
    aggregate: Aggregate,
  }) => Promise<Array<AggregateType>>

  async mounted () {
    const { aggregate } = this

    const fetch = ({ uid, filter, params, distinct }: Query) => {
      return this.fetchAggregates({ aggregate: { ...aggregate, where: filter } })
    }

    const variables = [
      {
        name: 'id', path: 'id',
      },
    ]
    const query = new Query({
      ...aggregate,
      variables,
      uid: 'id',
    }, this, fetch)

    const values = await query.value()

    this.data = {
      labels: values.map((val: any) => val.description),
      series: values.map(val => val.aggregates.value),
      colors: values.map((val: any) => val.color),
    }
  }

  buildChartInfo (title, labels, series, colors, isMonochrome = false) {
    const options = {
      chart: {
        width: '400',
        height: '100%',
        type: 'pie',
      },
      title: { text: title, align: 'center' },
      labels,
      legend: {
        position: 'bottom',
      },
      colors: [...colors],
    }

    if (isMonochrome) {
      Object.assign(options, {
        theme: {
          monochrome: {
            enabled: true,
            color: '#6185DB',
          },
        },
      })
    }

    if (!options.colors) {
      delete options.colors
    }

    return { series, options }
  }

  get chart () {
    const { buildChartInfo, data, title, monochrome } = this
    if (!data?.series?.length) return
    return buildChartInfo(title, data.labels, data.series, data.colors, monochrome)
  }
  }
